import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import LayoutGalerieImageInteractive from "../../../composants/layout/LayoutGalerieImageInteractive"
import OpenSeaDragonViewer from "../../../composants/image/OpenSeaDragonViewer"

import { obtenirPage } from "../../../js/client-es/utils"
import fragmentsPages from "../../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    page: allPagesCompilationYaml(filter: { code: { eq: "winterSkiesZoom" } }) {
      ...FragmentPageYaml
    }
  }
`

export default function WinterSkies(props) {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "page")

  const image = {
    xmlns: "http://schemas.microsoft.com/deepzoom/2009",
    Format: "jpg",
    Overlap: "1",
    Size: { Width: "10000", Height: "9941" },
    TileSize: "256",
    Url: "/ressources/deep-zoom/winter-skies/tuiles/",
  }

  const composantImageInteractive = <OpenSeaDragonViewer image={image} />

  return (
    <LayoutGalerieImageInteractive
      page={page}
      propsPage={props}
      composantImageInteractive={composantImageInteractive}
      codePageRetour="galerieZoomsEt360Degres"
    >
      <section className="section-conteneur">
        <p>
          Une mosaïque de 225 mégapixels du ciel hivernal, réalisée en 2023 et
          2024 depuis l’île de La Palma et depuis la France. Les experts
          repéreront les nombreux objets de cette région fantastique.
        </p>
      </section>
      <div id="galerie-image-interactive-texte-exifs">
        Canon EOS 6D Astrodon, Canon EF 135 mm F2 L fermé à F2.5, 1600 ISO.
        Mosaïque de 42 images. Temps de pose individuel : 1 minute, temps de
        pose total : environ 31 heures.
      </div>
    </LayoutGalerieImageInteractive>
  )
}
